var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"process_progress_status",attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),_c('button',{staticClass:"btn_admin",on:{"click":_vm.showLotSearch}},[_vm._v(" 작업지시서 불러오기 ")]),(
            _vm.target_lot != null &&
              _vm.findInfoFromId(_vm.lot_types, _vm.target_lot.lot_type_id).detail !=
                'wait process' &&
              _vm.isPermissionOn
          )?_c('button',{staticClass:"btn_admin",on:{"click":function($event){_vm.deleteProcessChk = true;
            _vm.FETCH_NO_SPINNER(
              'FETCH_PRODUCT_OCCUPIED_STOCK',
              '제품 사용중 재고'
            );}}},[_vm._v(" 진행내역 삭제 ")]):_vm._e(),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[_c(_vm.dynamicComponent,{tag:"component"})],1),_c('two-button-modal',{attrs:{"modal_title":'진행내역 삭제',"modal_content":'진행내역을 삭제하시겠습니까?',"isModalOpen":_vm.deleteProcessChk,"index":-1},on:{"onclose":function($event){_vm.deleteProcessChk = false},"OnYesClick":function($event){_vm.checkAndDeleteProduct();
      _vm.deleteProcessChk = false;}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }