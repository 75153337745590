<template>
  <div id="contents" class="process_progress_status">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="showLotSearch">
            작업지시서 불러오기
          </button>
          <button
            class="btn_admin"
            v-if="
              target_lot != null &&
                findInfoFromId(lot_types, target_lot.lot_type_id).detail !=
                  'wait process' &&
                isPermissionOn
            "
            @click="
              deleteProcessChk = true;
              FETCH_NO_SPINNER(
                'FETCH_PRODUCT_OCCUPIED_STOCK',
                '제품 사용중 재고',
              );
            "
          >
            진행내역 삭제
          </button>
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <component :is="dynamicComponent"></component>
    </div>
    <two-button-modal
      :modal_title="'진행내역 삭제'"
      :modal_content="'진행내역을 삭제하시겠습니까?'"
      :isModalOpen="deleteProcessChk"
      :index="-1"
      @onclose="deleteProcessChk = false"
      @OnYesClick="
        checkAndDeleteProduct();
        deleteProcessChk = false;
      "
    ></two-button-modal>
  </div>
</template>

<script>
// import ProductionForm from '@/views/forms/Process/Production/ProductionForm';
import { mapGetters } from 'vuex';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import fetchMixin from '@/mixins/fetch';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';

export default {
  mixins: [ModalMixin, SpinnerMixin, ClosePageMixin, FavoriteMixin, fetchMixin],
  components: {
    // ProductionForm,
    TwoButtonModal,
  },
  data() {
    return {
      deleteProcessChk: false,
    };
  },
  computed: {
    ...mapGetters({
      target_lot: 'getTargetLotFromProduction',
      lot_types: 'getLotType',
      keyCountForReDraw: 'getKeyCountForReDrawFromProduction',
      product_occupied_stock: 'getProductOccupiedStock',
      subMenu: 'getSubMenus',
    }),
    dynamicComponent() {
      const isMulti = this.subMenu.find(
        x => x.path == '/code/process-multi-select',
      );

      if (isMulti != undefined) {
        return () =>
          import('@/views/forms/Process/Production/ProductionMultiForm');
      } else
        return () => import('@/views/forms/Process/Production/ProductionForm');
    },
  },
  methods: {
    showLotSearch() {
      this.$store.commit('setShowLotSearchToProductionPage', true);
    },
    checkAndDeleteProduct() {
      if (Object.keys(this.target_lot).includes('product_in')) {
        const productInList = this.lodash.clonedeep(this.target_lot).product_in;
        let occupiedStockList = this.lodash
          .clonedeep(this.product_occupied_stock)
          .filter(
            x =>
              productInList.find(y => y.product_id == x.product_id) !=
              undefined,
          );

        occupiedStockList = occupiedStockList.filter(
          x =>
            this.$decimalSub(
              productInList
                .filter(y => y.product_id == x.product_id)
                .map(y => y.quantity)
                .reduce((a, b) => this.$decimalAdd(a, b), 0),
              x.quantity,
            ) < 0,
        );

        let isOverOccupied = occupiedStockList.length > 0 ? true : false;

        if (Object.keys(this.target_lot).includes('product_out')) {
          if (
            this.target_lot.product_out[0].length > 0 ||
            this.target_lot.product_out[1].length > 0
          ) {
            this.openOneButtonModal(
              '삭제 불가',
              '생산된 제품의 출고(사용)내역이 존재하므로<br/>공정 진행내역을 삭제할 수 없습니다.',
            );
            return;
          }
        }
        if (isOverOccupied) {
          this.openOneButtonModal(
            '삭제 불가',
            '생산된 제품이 다른 작업에서 사용될 예정이므로 <br /> 삭제할 수 없습니다.',
          );
          return;
        }
      }

      if (
        this.target_lot.lot_type_id ==
        this.lot_types.find(x => x.detail == 'done process').id
      ) {
        this.showSpinner();
        this.$store
          .dispatch('FETCH_PRODUCT_CONTROL_FROM_LOT_ALL', this.target_lot.id)
          .then(response => {
            if (response.data.data == 'not exist') {
              this.$store
                .dispatch('DELETE_LOT_PROCESS_LOT', this.target_lot.id)
                .then(async response => {
                  console.log(response);
                  this.openOneButtonModal(
                    '진행내역 삭제',
                    '생산 진행내역을 모두 삭제했습니다.',
                  );
                  await this.FETCH_LOT_PRODUCTION(this.target_lot.id);
                  this.$store.commit(
                    'setKeyCountForReDrawToProduction',
                    this.keyCountForReDraw + 1000,
                  );
                })
                .catch(error => {
                  console.log(error);
                  this.openOneButtonModal(
                    '삭제 중 오류',
                    '삭제 중 오류가 발생하였습니다.',
                  );
                });
            } else {
              const deleteProductControlList = response.data.data_list.map(
                x => x.id,
              );
              this.$store
                .dispatch(
                  'DELETE_PRODUCT_CONTROL_LIST',
                  deleteProductControlList,
                )
                .then(response => {
                  console.log(response);
                  this.$store
                    .dispatch('DELETE_LOT_PROCESS_LOT', this.target_lot.id)
                    .then(async response => {
                      console.log(response);
                      this.openOneButtonModal(
                        '진행내역 삭제',
                        '생산 진행내역과 생산된 제품을 모두 삭제했습니다.',
                      );
                      await this.FETCH_LOT_PRODUCTION(this.target_lot.id);
                      this.$store.commit(
                        'setKeyCountForReDrawToProduction',
                        this.keyCountForReDraw + 1000,
                      );
                    })
                    .catch(() => {
                      this.openOneButtonModal(
                        '삭제 중 오류',
                        '삭제 중 오류가 발생하였습니다.',
                      );
                    });
                })
                .catch(() => {
                  this.openOneButtonModal(
                    '삭제 중 오류',
                    '삭제 중 오류가 발생하였습니다.',
                  );
                });
            }
          })
          .catch(() => {
            this.openOneButtonModal(
              '삭제 중 오류',
              '삭제 중 오류가 발생하였습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        this.showSpinner();
        this.$store
          .dispatch('DELETE_LOT_PROCESS_LOT', this.target_lot.id)
          .then(async response => {
            console.log(response);
            this.openOneButtonModal(
              '진행내역 삭제',
              '생산 진행내역을 삭제했습니다.',
            );
            await this.FETCH_LOT_PRODUCTION(this.target_lot.id);
            this.$store.commit(
              'setKeyCountForReDrawToProduction',
              this.keyCountForReDraw + 1000,
            );
          })
          .catch(() => {
            this.openOneButtonModal(
              '삭제 중 오류',
              '삭제 중 오류가 발생하였습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async FETCH_LOT_PRODUCTION(lot_id) {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_LOT_PRODUCTION', lot_id)
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '공정 진행상태 로드 중 오류 발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitProductionPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
